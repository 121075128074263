import React, {useState} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {navigate} from 'gatsby';
import * as actions from '../../actions';
import CreatePayer from '../payment/CreatePayer';
import {
  Box,
  Grid,
  Button,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import CreatePlan from '../payment/CreatePlan';
import FormComponent from '../containers/FormComponent';
import BasePageContent from '../utils/BasePageContent';

const UpdateRenewedOrdersNotificationsModal = ({handleSubmit, toggleOpen, isOpen}) => {
  const [updateRenewed, setUpdateRenewed] = useState('following');

  const handleSubmitForm = (e) => {
    e.preventDefault()
    handleSubmit(updateRenewed)
  }

  return <Dialog onClose={toggleOpen} open={isOpen}>
    <form onSubmit={handleSubmitForm}>
      <DialogTitle>
        Edycja planu powiadomień płatności cyklicznej
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel id='radio-buttons-group-label'>
            Aktualizuj
          </FormLabel>
          <RadioGroup
            aria-labelledby='radio-buttons-group-label'
            defaultValue='following'
            name='radio-buttons-group'
            value={updateRenewed}
            onChange={(_, value) => setUpdateRenewed(value)}
          >
            <FormControlLabel
              value='no'
              control={<Radio/>}
              label='Tylko tą płatność'
            />
            <FormControlLabel
              value='following'
              control={<Radio/>}
              label='Tą i kolejne płatności'
            />
            <FormControlLabel
              value='all'
              control={<Radio/>}
              label='Wszystkie płatności'
            />
          </RadioGroup>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen}>Anuluj</Button>
        <Button
          variant='contained'
          disableElevation
          type='submit'
        >
          Zatwierdź
        </Button>
      </DialogActions>
    </form>
  </Dialog>
}


const shouldRenewalModalBeOpened = (order, modalOpen) => {
  return order.orderRenewal?.status === 'active' && !modalOpen;
}

const mapStateToProps = state => ({
  debtorOrder: state.createDebtor,
  notificationsPlanOrder: state.createNotificationsPlan,
  planProposition: state.getPlanProposition,
  order: state.getOrder,
});

class Notifications extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      step: 0,
      loading: false,
      payerCode: null,
      updateRenewedModalOpen: false,
    };
  }

  toggleUpdateRenewedModalOpen = () => {
    this.setState(({updateRenewedModalOpen: !this.state.updateRenewedModalOpen}))
  }

  componentDidMount() {
    const { order } = this.props;
    if (!order || order.data.code !== this.props.code) {
      this.setState({ loading: true }, () => {
        this.props.actions.getOrder(this.props.code);
      });
    } else if (order?.debtor?.code) {
      this.setState({payerCode: order.debtor.code});
    }
  }

  componentDidUpdate(prevProps) {
    const {
      order,
      debtorOrder,
      planProposition,
      notificationsPlanOrder,
    } = this.props;

    if (order !== prevProps.order) {
      this.setState({loading: false, payerCode: order?.debtor?.code || ''});
    }

    if (debtorOrder !== prevProps.debtorOrder) {
      this.validateResponse(debtorOrder, () => {
        if (debtorOrder.debtor) {
          this.setState({step: 1, loading: true,});
          this.props.actions.getPlanProposition(this.props.code);
        }
      });
    }

    if (planProposition !== prevProps.planProposition) {
      if (planProposition.data) {
        const currentPlan = order?.notificationsPlan?.plan;
        const plan = Object.keys(planProposition.data).reduce(
          (acc, curr) => ({...acc, [curr]: !currentPlan || currentPlan.some(({template}) => template === curr)}),
          {}
        );
        this.setState({
          planProposition: planProposition.data,
          plan,
          loading: false,
        });
      }
    }

    if (notificationsPlanOrder !== prevProps.notificationsPlanOrder) {
      if (notificationsPlanOrder.notificationsPlan) {
        navigate(`/orders/${this.props.code}`);
      }
    }
  }

  handlePayerChange = (payerCode) => this.setState({payerCode});

  handlePlanItemChange = ({target}) => {
    const {name, checked} = target;
    const plan = {...this.state.plan, [name]: checked};
    this.setState({plan});
  };

  handlePayerSubmit = (payerCode = null) => {
    this.props.actions.createDebtor(this.props.code, {payerCode: payerCode || this.state.payerCode});
  };

  handlePlanSubmit = (updateRenewed = null) => {
    const plan = Object.entries(this.state.plan).reduce(
      (acc, [name, isChosen]) => {
        return isChosen ? [...acc, name] : acc;
      },
      []
    );
    if (plan.length) {
      if (shouldRenewalModalBeOpened(this.props.order, this.state.updateRenewedModalOpen)) {
        this.setState({ loading: false });
        this.toggleUpdateRenewedModalOpen();
      } else {
        this.setState({ loading: true });
        const data = {plan};
        if(updateRenewed) data.updateRenewed = updateRenewed;
        this.props.actions.createNotificationsPlan(this.props.code, data);
      }
    } else {
      this.navigateToOrder();
    }
  };

  navigateToOrder = () => navigate(`/orders/${this.props.code}`);
  handleSubmit = e => {
    e.preventDefault();
    if (this.state.step === 0) {
      this.handlePayerSubmit();
    } else {
      this.handlePlanSubmit();
    }
  };

  renderDescription = () => {
    const descriptions = [
      [
        'Podaj email płatnika, na który wyślemy przypomnienia o czekającej płatności',
      ],
      ['Tu możesz dostosować plan przypomnień o płatności', 'Oto nasz plan:'],
    ];

    return descriptions[this.state.step].map(desc => (
      <Typography key={desc} variant='body1' paragraph>
        {desc}
      </Typography>
    ));
  };

  renderActionButtons = () => {
    return (
      <Box p={4}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              onClick={() =>
                this.state.step
                  ? this.setState({ step: 0 })
                  : this.navigateToOrder()
              }
            >
              {this.state.step ? 'Wstecz' : 'Anuluj'}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              disabled={!this.state.payerCode}
              type='submit'
            >
              Dalej
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  render() {
    const {
      loading,
      step,
      payerCode,
      plan,
      planProposition,
      formErrors,
    } = this.state;

    return (
      <BasePageContent
        title='Przypomnienia o płatności'
        loading={loading}
        centered
      >
        {this.renderDescription()}
        {!loading && (
          <>
            <form
              onSubmit={this.handleSubmitForm.bind(
                this,
                step === 0 ? this.handlePayerSubmit : this.handlePlanSubmit
              )}
            >
              {step === 0 ? (
                <CreatePayer
                  submitPayer={this.handlePayerSubmit}
                  handleChange={this.handlePayerChange}
                  payerCode={payerCode}
                  error={formErrors.payerCode}
                />
              ) : (
                <CreatePlan
                  handleChange={this.handlePlanItemChange}
                  orderCode={this.props.code}
                  plan={plan}
                  planProposition={planProposition}
                />
              )}
              {this.renderActionButtons()}
            </form>
            <UpdateRenewedOrdersNotificationsModal
              toggleOpen={this.toggleUpdateRenewedModalOpen}
              isOpen={this.state.updateRenewedModalOpen}
              handleSubmit={this.handlePlanSubmit}
            />
          </>
        )}
      </BasePageContent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
