import React, {useEffect, useRef, useState} from 'react';
import {Box, Checkbox, FormControlLabel, FormGroup, Grid} from '@mui/material';
import FindPayerSelect from "../payers/FindPayerSelect";
import PayerDataDialog from "../payers/PayerDataDialog";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as actions from '../../actions';

const mapStateToProps = state => ({createdPayer: state.createPayer});

const CreatePayer = ({payerCode, handleChange, createdPayer, actions, submitPayer}) => {
  const [createPayerDialogOpen, setCreatePayerDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const createdPayerCodeRef = useRef(createdPayer?.data?.code || null);

  const toggleDialogOpen = () => setCreatePayerDialogOpen(!createPayerDialogOpen);

  useEffect(() => {
    if (createdPayer?.data && createdPayer.data.code !== payerCode) {
      if (createdPayerCodeRef.current !== createdPayer?.data.code) {
        const {code} = createdPayer.data;
        actions.getPayers();
        handleChange(code);
        submitPayer(code);
      }
    }
  }, [createdPayer, actions, handleChange, submitPayer, payerCode]);

  const createAndSetPayer = (data) => {
    setLoading(true);
    actions.createPayer(data);
  }

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} md={6}>
        <Box pt={2} textAlign='center'>
          <FindPayerSelect handleChange={handleChange} payerCode={payerCode}/>
        </Box>
        <Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={createPayerDialogOpen}
                  onChange={(e, checked) => setCreatePayerDialogOpen(checked)}
                />
              }
              label='Płatnik nie figuruje na liście'
            />
          </FormGroup>
          <PayerDataDialog
            loading={loading}
            errors={createdPayer?.status === 'ERROR' ? createdPayer.details : {}}
            dialogOpen={createPayerDialogOpen}
            closeDialog={toggleDialogOpen}
            handleSubmit={createAndSetPayer}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})
export default connect(mapStateToProps, mapDispatchToProps)(CreatePayer);
