import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  TextField
} from "@mui/material";


const INITIAL_ERRORS = {
  firstName: '',
  lastName: '',
  name: '',
  email: '',
  phonePrefix: '',
  phoneNumber: '',
};

const formatPayerData = data => {
  if (!data.phoneNumber) {
    return data;
  }

  return {
    ...data,
    phoneNumber: data.phoneNumber.replace(/[^a-z0-9]/gmi, ""),
  };
};

const PayerDataDialog = ({
  payerData = {},
  loading = false,
  errors = {},
  handleSubmit,
  dialogOpen,
  closeDialog
}) => {
  const [data, setData] = useState(payerData);
  const [validationErrors, setValidationErrors] = useState(INITIAL_ERRORS);

  const isValid = () => {
    const hasValidationErrors = Object.values(validationErrors).some(err => !!err);

    const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegExp.test(data.email) && !hasValidationErrors;
  }

  useEffect(() => {
    if(errors) setValidationErrors({...errors});
  }, [errors]);

  useEffect(() => {
    const data = { ...payerData };
    Object.keys(data).forEach((key) => data[key] === null && delete data[key]);

    setData(data);
    // setData((payerData));
    setValidationErrors(INITIAL_ERRORS);
  }, [payerData?.code]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formatted = formatPayerData(data);
    console.log(formatted)

    if (!isValid()) return;
    handleSubmit(formatted)
  };

  const handleClose = () => {
    closeDialog();
    setData((payerData));
    setValidationErrors(INITIAL_ERRORS);
  }

  const handleChange = e => {
    setData({...data, [e.target.name]: e.target.value});
    setValidationErrors({ ...validationErrors, [e.target.name]: ''});
  }

  return <Dialog
    open={dialogOpen}
    onClose={handleClose}
    maxWidth='xs'
  >
    <form onSubmit={onSubmit}>
      <DialogTitle>Dane płatnika</DialogTitle>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre>
      <pre>{JSON.stringify(loading, null, 2)}</pre> */}
      <DialogContent>
        <Box sx={{py: 2}} display='flex'>
          <TextField
            value={data.firstName || ''}
            onChange={handleChange}
            variant="outlined"
            label='Imię'
            name='firstName'
            error={!!validationErrors.firstName}
            helperText={validationErrors.firstName}
            sx={{mr:2}}
          />
          <TextField
            value={data.lastName || ''}
            onChange={handleChange}
            variant="outlined"
            label='Nazwisko'
            name='lastName'
            error={!!validationErrors.lastName}
            helperText={validationErrors.lastName}
          />
        </Box>
        <TextField
          value={data.name || ''}
          onChange={handleChange}
          variant="outlined"
          label='Nazwa'
          fullWidth
          name='name'
          error={!!validationErrors.name}
          helperText={validationErrors.name}
          sx={{my: 2}}
        />
        <TextField
          value={data.email || ''}
          onChange={handleChange}
          variant="outlined"
          type='email'
          name='email'
          error={!!validationErrors.email}
          helperText={validationErrors.email}
          label='Email'
          fullWidth
          sx={{my: 2}}
          required
        />
        <Box sx={{my: 2, display:'flex'}}>
          <Box sx={{maxWidth:'100px'}}>
            <Select
              value={data.phonePrefix || ''}
              onChange={handleChange}
              name='phonePrefix'
              error={!!validationErrors.phonePrefix}
              label='Numer kierunkowy kraju'
              sx={{mr: 2, width:'80px'}}
              defaultValue=''
            >
              <MenuItem value='+48'>+48</MenuItem>
              <MenuItem value=''>brak</MenuItem>
            </Select>
            <FormHelperText>{validationErrors.phonePrefix}</FormHelperText>
          </Box>
          <TextField
            value={data.phoneNumber || ''}
            onChange={handleChange}
            variant="outlined"
            label='Numer telefonu'
            error={!!validationErrors.phoneNumber}
            helperText={validationErrors.phoneNumber}
            name='phoneNumber'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>Anuluj</Button>
        <Button variant='contained' type='submit' disabled={!isValid()} disableElevation>Zapisz</Button>
      </DialogActions>
    </form>
  </Dialog>
}

export default PayerDataDialog;
