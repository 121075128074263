import React from 'react';
import Layout from '../../../templates/layout';
import Notifications from '../../../components/user/notifications';

const NotificationsPage = props => {
  return (
    <Layout>
      <Notifications code={props.code} />
    </Layout>
  );
};

export const Head = () => <title>Powiadomienia | Zapłatomat</title>;

export default NotificationsPage;
