import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as actions from '../../actions';
import {bindActionCreators} from "redux";
import BaseLoader from "../utils/BaseLoader";

const formatOption = (payerData) => {
  const {code, firstName, lastName, name, email, phoneNumber} = payerData;

  let label = firstName ? `${firstName} ` : '';
  label += lastName ? `${lastName} | ` : '';
  label += name ? `${name} | ` : '';
  label += email;
  label += phoneNumber ? ` | ${phoneNumber}` : '';

  const hasFirstAndLastName = firstName && lastName;
  return {code, label, email, name: name || (hasFirstAndLastName && `${firstName} ${lastName}`) || ''};
}

const mapStateToProps = state => ({payers: state.getPayers})

const FindPayerSelect = ({payers, actions, payerCode, handleChange}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    actions.getPayers({search: true});
  }, [actions])

  useEffect(() => {
    if (payers?.data) {
      setOptions(payers.data.map(formatOption));
      setLoading(false)
    }
  }, [payers]);

  if (loading) return <BaseLoader loading={true}/>

  return <>
    {!loading && <Autocomplete
      defaultValue={options.find(({code}) => payerCode === code) || null}
      value={options.find(({code}) => payerCode === code) || null}
      onChange={(_, value) => handleChange(value?.code || null)}
      getOptionLabel={({label}) => label}
      isOptionEqualToValue={({code}) => payerCode === code}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Wyszukaj"
        />)}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.code}>
          <Grid container>
            <Grid item xs={6} sm={9}>
              {option.email}
            </Grid>
            <Grid item xs={6} sm={3}>
              {option.name}
            </Grid>
          </Grid>
        </Box>
      )}
      options={options}
    />
    }
  </>
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})
export default connect(mapStateToProps, mapDispatchToProps)(FindPayerSelect)
