import React, { Component } from 'react';
import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Chip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotificationPreview from '../NotificationPreview';
import BaseTooltip from '../utils/BaseTooltip';

const severityToColor = {
  low: '#3ae23a',
  midLow: '#a2ff44',
  mid: '#fbff00',
  midHigh: '#ff9901',
  high: '#ff4747',
  none: 'hsl(0, 0%, 85%)',
};

class CreatePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewOpen: false,
      previewTemplate: null,
    };
  }

  openPreview = template => {
    this.setState({ previewOpen: true, previewTemplate: template });
  };

  closePreview = () => {
    this.setState({ previewOpen: false, previewTemplate: null });
  };

  render() {
    const { planProposition, plan, handleChange, orderCode } = this.props;
    const { previewOpen, previewTemplate } = this.state;
    return (
      <Grid container justifyContent='center'>
        <NotificationPreview
          open={previewOpen}
          template={previewTemplate}
          orderCode={orderCode}
          handleClose={this.closePreview}
        />
        <TableContainer style={{ maxWidth: '600px' }}>
          <Table size='small' aria-label='table'>
            <TableBody>
              {Object.entries(planProposition).map(([name, notification]) => (
                <TableRow key={name}>
                  <TableCell>
                    <Chip
                      style={{
                        backgroundColor: severityToColor[notification.severity],
                      }}
                      label={notification.message}
                      deleteIcon={
                        <BaseTooltip title='Podgląd wiadomości' placement='top'>
                          <InfoOutlinedIcon />
                        </BaseTooltip>
                      }
                      onDelete={() => this.openPreview(name)}
                    />
                  </TableCell>
                  <TableCell>
                    <Switch
                      color='primary'
                      name={name}
                      checked={plan[name]}
                      onChange={handleChange}
                      aria-label='login switch'
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    );
  }
}

export default CreatePlan;
